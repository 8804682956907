.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.title {
    max-width: 300px;
}

.animation {
    height: 180px;
    margin-top: -40px;
    width: auto;
}

@media screen and (min-width: 1024px) {
    .container {
        flex-direction: row;
    }

    .title {
        max-width: unset;
    }

    .animation {
        margin-top: 0;
    }
}