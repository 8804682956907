.footer {
    display: flex;
    width: 100%; /* Set width to 100% */
    align-items: center;
    flex-direction:column;
    padding-top: 250px;
    padding-bottom: 100px;
}

.social-media-icons {
    display: flex;
    gap: 1rem;
}

/* Styling for social media icons links */
.social-media-icons a {
    color: white;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
}

.social-media-icons a:hover {
    color: var(--green);
}

/* Styling for the footer logo */
.logo {
    background-color: var(--black);
    color: var(--white);
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    padding:2px;
    border-radius: 3px;
}

/* Styling for the copyright text */
.copyrigth {
    color: white;
    font-size: 0.8rem;
    margin: 0;
}

/* Add custom styling for LinkedIn icon */
.social-media-icons a:nth-child(3) {
    color: white;
    transition: color 0.3s ease-in-out;
}

.social-media-icons a:nth-child(3):hover {
    color: var(--blue); /* Change color on hover */
}

@media only screen and (max-width: 365px) {
    .footer {
        padding: 1rem 1rem;
    }
    .social-media-icons {
        gap: 0.2rem;
    }
}
